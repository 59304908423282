<template>
  <div
    class="p-6 pb-base vs-con-loading__container"
    :id="`div-with-loading-${report._id}`"
  >
    <vx-card :title="title">
      <div slot="no-body" class="p-6 pb-0">
        <div class="flex">
          <div class="mr-6">
            <p class="mb-1 font-semibold">{{ chartData.series[1].name }}</p>
            <p class="text-3xl">{{ total }}</p>
          </div>
          <div>
            <p class="mb-1 font-semibold">{{ chartData.series[0].name }}</p>
            <p class="text-3xl">{{ derived }}</p>
          </div>
        </div>
        <vue-apex-charts
          type="line"
          height="290"
          :options="chartData.chartOptions"
          :series="chartData.series"
        />
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'TakoversHistogramV1',
  props: {
    report: {
      type: Object,
      required: true
    },
    type: {
      type: Array
    },
    platforms: {
      type: Array
    },
    services: {
      type: Array,
      required: true
    },
    interval: Object,
    versions: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    title() {
      const found = this.report.name.find(element => {
        return element.lang === this.languageSelected
      })
      if (found) {
        return found.text
      }
      return 'N/A'
    }
  },
  data() {
    return {
      count: 0,
      derived: 0,
      total: 0,
      chartData: {
        series: [
          {
            name: '',
            data: []
          },
          {
            name: '',
            data: []
          }
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false }
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 8],
            width: [4, 2]
          },
          grid: {
            borderColor: '#e7e7e7'
          },
          legend: {
            show: false
          },
          colors: ['#F97794', '#b8c2cc'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: ['#7367F0', '#b8c2cc'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100]
            }
          },
          markers: {
            size: 0,
            hover: {
              size: 5
            }
          },
          xaxis: {
            labels: {
              style: {
                cssClass: 'text-grey fill-current'
              }
            },
            axisTicks: {
              show: false
            },
            categories: [],
            axisBorder: {
              show: false
            }
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                cssClass: 'text-grey fill-current'
              },
              formatter: function(val) {
                return val > 999 ? (val / 1000).toFixed(1) + 'k' : val
              }
            }
          },
          tooltip: {
            x: { show: false }
          }
        }
      }
    }
  },
  components: {
    VueApexCharts
  },
  watch: {
    services() {
      this.load(this.report)
    },
    interval() {
      this.load(this.report)
    },
    platforms() {
      this.load(this.report)
    },
    versions() {
      this.load(this.report)
    }
  },
  methods: {
    ...mapActions('metrics', ['getReportData']),
    async load(report) {
      const label1 = this.report._extra.label1.find(element => {
        return element.lang === this.languageSelected
      })
      if (label1) {
        this.chartData.series[0].name = label1.text
      } else {
        this.chartData.series[0].name = ''
      }
      const label2 = this.report._extra.label2.find(element => {
        return element.lang === this.languageSelected
      })
      if (label2) {
        this.chartData.series[1].name = label2.text
      } else {
        this.chartData.series[1].name = ''
      }
      if (!this.services || this.services.length === 0) {
        return
      }
      if (!this.versions || this.versions.length === 0) {
        return
      }
      if (!this.platforms || this.platforms.length === 0) {
        return
      }
      const data = {
        type: this.type,
        services: this.services,
        versions: this.versions,
        platforms: this.platforms
      }
      this.$vs.loading({
        container: `#div-with-loading-${report._id}`,
        scale: 1.0
      })
      try {
        const result = await this.getReportData({
          reportId: report._id,
          payload: data
        })
        let info = []
        let derivedCount = 0
        result.data[0].forEach(element => {
          if (this.chartData.chartOptions.xaxis.categories.length < 12) {
            this.chartData.chartOptions.xaxis.categories.push(element.date)
          }
          info.push(element.count)
          derivedCount += parseInt(element.count)
        })
        this.derived = derivedCount
        this.chartData.series[0].data = info
        let totalInfo = []
        let totalCount = 0
        result.data[1].forEach(element => {
          totalInfo.push(element.count)
          totalCount += parseInt(element.count)
        })
        this.total = totalCount
        this.chartData.series[1].data = totalInfo
        this.$vs.loading.close(
          `#div-with-loading-${report._id} > .con-vs-loading`
        )
      } catch (ex) {
        if (ex.message !== 'cancelled') {
          this.$vs.loading.close(
            `#div-with-loading-${report._id} > .con-vs-loading`
          )
          const reportName =
            this.languageSelected === 'es'
              ? report.name[0].text
              : report.name[1].text
          this.$vs.notify({
            text:
              this.lang.metrics.reportMessages.text[this.languageSelected] +
              `"${reportName}"`,
            color: 'danger'
          })
          console.error(ex)
        }
      }
    }
  },
  mounted() {
    this.load(this.report)
  }
}
</script>
